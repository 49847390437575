/**
 * Simple wrapper for a span with class='remark'. Makes other components slightly more elegant:
 * 
 * <Remark>Blah blah blah</Remark>
 */

import React from "react"
import PropTypes from "prop-types"

const Remark = ({ children }) => {
  return <span className='remark'>{children}</span>;
}

Remark.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Remark
